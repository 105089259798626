export default [
  {
    title: 'Início',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Dispositivos',
    route: 'devices',
    icon: 'BoxIcon',
  },
 
  {
    title: 'Dashboards',
    route: 'dashboards',
    icon: 'LayoutIcon',
  },
  {
    title: 'Eventos',
    route: 'conditions',
    icon: 'TriangleIcon',
  },
  {

    
    title: 'Mapa',
    route: 'map',
    icon: 'MapIcon',
  },

  {
    title: 'Relatórios',
    route: 'reports_table',
    icon: 'FileIcon',
  },

 
  {
    title: 'Grupo',
    icon: 'UserIcon',
    children: [
      {
        title: 'Ver grupos',
        route: 'view-groups',
      },
      {
        title: 'Membros',
        route: 'users',
      },
      {
        title: 'Criar grupo',
        route: 'add-group',
      },
    ],
  },  
]
