<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block"/>
    </div>



    <!-- Right Col -->   
    <b-dropdown
      style="margin-right: 2rem"
      :text="$store.state.active_group.name"
      variant="flat-dark"
    >
      <b-dropdown-item 
      v-for="possible_groups in $store.state.group_exist" :key="'group_' + possible_groups.id"
      @click="$store.state.active_group = possible_groups;"
      > 
        {{possible_groups.name}}
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item
      to="/add-group">
        Criar grupo
      </b-dropdown-item>
    </b-dropdown>

    <!-- <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
      <template #button-content>
        <feather-icon
          :badge="possible_events.length"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <li class="dropdown-menu-header" style="margin-bottom: 0.5rem" :key="'Icon_' + cont">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Notificações
          </h4>
          <b-badge
            pill
            variant="light-success"
          >
            {{possible_events.length}} novas
          </b-badge>
        </div>
      </li>

      <a v-if="possible_events.length > 0" @click="change_page('conditions')" :key="'Notification_'+cont">
        <b-media no-body style="padding:1rem;" class="notifications">
          <b-media-aside class="mr-1">
            <b-avatar 
            variant="light-warning" 
            style="margin-right: 0.5rem; margin-top: 0rem">
              <feather-icon icon="BellIcon" size="20" />
            </b-avatar>    
          </b-media-aside>
          <b-media-body class="my-auto">
            <h5 class="font-weight-bolder mb-0">
              {{possible_events.length}} eventos ocorridos!
            </h5>
            <b-card-text class="font-small-3 mb-0">
              Clique para mais detalhes
            </b-card-text>
          </b-media-body>
        </b-media>
        <b-dropdown-divider />
      </a>
      
      <h6 v-else style="margin-left: 1.5rem">
        Nenhuma notificação
      </h6>


    </b-nav-item-dropdown> -->
      
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ get_info.nome}}
            </p>
            <b-badge variant= "success" style="font-size: 75%">
              Online
            </b-badge>
          </div>
          <b-avatar
            size="40"
            variant="light-secondary"
            badge
            badge-variant="success"
            class="badge-minimal"
            :text="first_letter[0]"
          />
        </template>

        <b-dropdown-item to="edit-profile" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Suporte</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

  </div>
  
</template>

<script>
import {
  BLink, BNavbarNav, BBadge, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,BDropdown,BCardText, BForm, BFormInput, BFormGroup, BFormTextarea, BButton,
  BMedia, BMediaAside, BMediaBody, BRow, BCol
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from "axios";
import Cookies from 'js-cookie'
import store from '@/store/index'
import router from '@/router'
import Ripple from 'vue-ripple-directive'
import EventBus from '@/store/eventBus'

export default {
  
  components: {
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BDropdown,
    BCardText,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BButton,
    ToastificationContent,
    BBadge,
    DarkToggler,
  },
  directives: {
    Ripple,
  },
  created(){
    if (Cookies.get("token_api") != null) {
      store.state.token_api = Cookies.get("token_api");
    }
    if(Cookies.get('token_api') != undefined){
      this.initial_requests();
    }  

    EventBus.$on('patch_event', () => {
      this.patch_event();
    });
    // this.refresh_data = setInterval(this.patch_event, 2000);
    
    },
    destroyed(){
      this.destroy_it = true;
    },
  data() {
    return {     
      destroy_it: false, 
      refresh_data: [],
      cont: 0,
      possible_events: [],
      first_letter: '',
      get_info: [],
      on_group: 0,
    }
  },
  methods: {
    async patch_event(){
      var self = this;
      if(self.destroy_it){
        clearInterval(self.refresh_data);
      }
      await axios
      .get('https://tekie.cloud:3031/eventos?idGroup=' + store.state.active_group.id, {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })  
      .then(function (response) {
        if(response.data.error == undefined){
          self.possible_events =  response.data.eventos.data.filter(filter_data => filter_data.visto == 0)
        }
      })
      
      .catch(function (e) {
        //"Erro ao tentar achar eventos: ", e)
      });
      self.cont += 1;
    },
    change_page(location){
      let self = this;
      self.$router.push({ path: location })
    },
    async initial_requests(){
      var self = this;

      await axios
      .get('https://tekie.cloud:3031/group', {
          headers: {
            Authorization: this.$store.state.token_api,
          },
        })   
      .then(function (response) {
        store.state.group_exist = response.data.data;
        if(store.state.group_exist.length >= 1){
          store.state.active_group = response.data.data[0]
      }
    })
      .catch(function (e) {
        //"Erro ao tentar achar grupos: ",e);
      });
  
    await axios
    .get('https://tekie.cloud:3031/users', {
        headers: {
          Authorization: this.$store.state.token_api,
        },
      })   
    .then(function (response) {
      self.get_info = response.data.data[0];
      self.first_letter = self.get_info.nome.split('', 1)
    })
    .catch(function (e) {
      Cookies.remove('token_api')
      self.$router.push({ path: 'error-401' })
      //"Erro em requisição de usuários:",e);
    });


    let data = {
      statusOn: 1,
    }
    
    const headers = { 
      "Authorization": store.state.token_api,
     };
    await axios.patch('https://tekie.cloud:3031/users', data, { headers })
      .then(function (response) {
      })
      .catch(function (error) {
        //error);
      });

    await axios
    .get('https://tekie.cloud:3031/eventos?idGroup=' + store.state.active_group.id, {
      headers: {
        Authorization: this.$store.state.token_api,
      },
    })  
    .then(function (response) {
      if(response.data.error == undefined){
        self.possible_events =  response.data.eventos.data.filter(filter_data => filter_data.visto == 0)
      }
    })
    
    .catch(function (e) {
      //"Erro ao tentar achar eventos: ", e)
    });

    
    },
    showToast(variant, title, icon, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      })
    },   
    logout(){
    clearInterval(store.state.refresh_interval);
      
    let data = {
      statusOn: 0,
    }
    
    var patch_profile = {
      method: 'patch',
      url: 'https://tekie.cloud:3031/users',
      headers: { 
        "Content-Type": "application/json",
        'Authorization': store.state.token_api
      },
      data: data
    };

    axios(patch_profile)
    .then(function (response) {
    })
    .catch(function (error) {
      //error);
    });

      store.state.token_api = "";
      Cookies.remove("token_api");
      // IMPLEMENTAR: Fazer requisição DELETE com axios na api do Pablo. Update_1 = Que isso?
      router.push("login");
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  
}

</script>

<style lang="scss">
  .notifications:hover{
    background: #f8f8f8
  }
  .dropdown-item:hover, .dropdown-item:focus{
    color: #ff4804;
  }
  [dir] .dropdown-item:hover, [dir] .dropdown-item:focus{
    background-color: #ffebe3
  }
</style>
