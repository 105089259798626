<template>
  <p class="clearfix mb-0">

    <!--<span class="float-md-right d-none d-md-block">Feito com
      <feather-icon
        icon="HeartIcon"
        size="43"
        class="text-danger stroke-current"
      />
      por Tekie
    </span>-->
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
